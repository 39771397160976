import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

import { CreateUserOptionLabel } from 'modules/shared/users-dropdown'
import DataTable from 'theme/ui/data-table'
import { isAllowTo } from 'utilities/helpers'
import { permissions } from 'utilities/permissions'
import StatusColumn from './status-column'
import TaskActions from './task-actions'

const TaskTable = ({
	data,
	meta,
	isLoading,
	pagination,
	setPagination,
	columnVisibility,
	setColumnVisibility,
}) => {
	const [sorting, setSorting] = useState([])

	// console.log('columnVisibility', columnVisibility)

	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor((row) => row.index, {
			id: '#',
			header: () => <span>#</span>,
			size: 20,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'summary',
			size: 180,
			header: () => <span>Summary</span>,
			cell: (info) => (
				<Link
					to={isAllowTo(permissions?.task?.edit) ? `/tasks/${info.getValue()?.id}` : '#'}
					className='link line-clamp-1 text-sm font-bold !text-main'
					title={info.getValue()?.task_title}>
					{info.getValue()?.task_title}
				</Link>
			),
		}),

		columnHelper.accessor('workflow', {
			header: () => <span>Workflow</span>,
			cell: (info) => (
				<>
					{info.getValue()?.title ? (
						<span
							className='tag tag-info line-clamp-1 max-w-max'
							title={info.getValue()?.title}>
							{info.getValue()?.title}
						</span>
					) : undefined}
				</>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'progress_status',
			size: 90,
			header: () => <span>Progress Status</span>,
			cell: (info) => <StatusColumn task={info.getValue()} />,
		}),

		// columnHelper.accessor('clients', {
		// 	header: () => <span>Clients</span>,
		// 	size: 200,
		// 	cell: (info) => {
		// 		const _clients = useMemo(() => {
		// 			const clients = info.getValue()
		// 			if (Array.isArray(clients)) {
		// 				return clients
		// 					.map((client) => ({
		// 						name: `${client?.first_name} ${client?.last_name}`,
		// 						id: client?.id,
		// 					}))
		// 					.slice(0, 3)
		// 			} else {
		// 				return []
		// 			}
		// 		}, [info.getValue()])

		// 		return (
		// 			<div>
		// 				{_clients.length > 0 && (
		// 					<div className='flex flex-row items-center gap-2'>
		// 						<Badge
		// 							variant='primary'
		// 							className='line-clamp-1 flex cursor-pointer'
		// 							onClick={() => handleClientClick(_clients[0].id)}>
		// 							{_clients[0].name}
		// 						</Badge>
		// 						{_clients.length > 1 && (
		// 							<TaskPopover
		// 								data={_clients}
		// 								title='Groups'
		// 								onClick={handleClientClick}
		// 							/>
		// 						)}
		// 					</div>
		// 				)}
		// 			</div>
		// 		)
		// 	},
		// }),

		// columnHelper.accessor('tags', {
		// 	header: () => <span>Tags</span>,
		// 	cell: (info) => <TagList tags={info.getValue()} />,
		// }),

		columnHelper.accessor('assignee', {
			header: () => <span>Assignee</span>,
			cell: (info) => (
				<>
					{info.getValue() ? (
						<CreateUserOptionLabel user={info.getValue()} showEmail={false} />
					) : undefined}
				</>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'actions',
			size: 70,
			enableSorting: false,
			header: () => (
				<div className='w-full text-right'>
					<span>Actions</span>
				</div>
			),
			cell: (info) => <TaskActions task={info.getValue()} />,
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			meta={meta}
			setSorting={setSorting}
			sorting={sorting}
			columnVisibility={columnVisibility}
			setColumnVisibility={setColumnVisibility}
		/>
	)
}

export default TaskTable
