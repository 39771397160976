/* eslint-disable react-hooks/exhaustive-deps */
import {
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect } from 'react'

import classNames from 'classnames'
import { useTableFilters } from 'contexts/table-filter-context'
import DataTablePagination from './data-table-pagination'
import TableHeader from './table-header'
import TbodySekeleton from './tbody-sekeleton'

const DataTable = ({
	columns,
	data,
	isLoading,
	meta,
	pagination,
	setPagination,
	onMouseEnter,
	onMouseLeave,
	sorting,
	setSorting,
	isFullRowSelection,
	setRowSelection,
	rowSelection,
	setColumnVisibility,
	columnVisibility,
	isRowSelection,
	variant,
	isOverflow = true,
	renderSubComponent,
	getRowCanExpand,
	hideHeader = false,
	isCompact = false,
	isChildren = false,
}) => {
	const table = useReactTable({
		columns: columns || [],
		data: data || [],
		getRowCanExpand: getRowCanExpand,
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getSortedRowModel: getSortedRowModel(),
		manualPagination: true,
		pageCount: meta?.totalPages ?? -1,
		state: { pagination, sorting, rowSelection, columnVisibility: columnVisibility },
		onPaginationChange: setPagination,
		onSortingChange: setSorting,
		onColumnVisibilityChange: setColumnVisibility,
		enableSorting: true,
		enableHiding: true,
		onRowSelectionChange: setRowSelection,
		enableRowSelection: isRowSelection,
		getRowId: (row) => (isFullRowSelection ? JSON.stringify(row) : row.id),
	})
	const tableContext = useTableFilters()
	useEffect(() => {
		if (table && tableContext?.setFilters) {
			tableContext.setFilters(table)
		}
	}, [table, columnVisibility])

	return (
		<React.Fragment>
			<div className={`${isOverflow ? 'overflow-auto' : ''} `}>
				<table
					className={classNames(
						variant === 'secondary' ? 'react-table-secondary' : 'react-table',
					)}>
					<TableHeader table={table} hideHeader={hideHeader} />

					<Fragment>
						{isLoading ? (
							<TbodySekeleton table={table} isCompact={isCompact} />
						) : (
							<tbody>
								{table.getRowModel().rows?.length > 0 ? (
									<Fragment>
										{table.getRowModel().rows.map((row) => (
											<Fragment key={row.id}>
												<tr
													onMouseEnter={
														onMouseEnter
															? () =>
																	onMouseEnter(
																		row?.original?.uuid ||
																			row?.id,
																	)
															: null
													}
													onMouseLeave={onMouseLeave}>
													{row.getVisibleCells().map((cell) => (
														<td
															key={cell.id}
															className={classNames(
																isCompact
																	? '!px-4 py-2'
																	: 'h-[48px] !px-4 py-2',
																isChildren
																	? row.getIsExpanded()
																		? 'bg-[#E7EDFF]'
																		: 'bg-slate-100'
																	: row.getIsExpanded()
																	? 'bg-[#E7EDFF]'
																	: 'bg-white',
															)}>
															{flexRender(
																cell.column.columnDef.cell,
																cell.getContext(),
															)}
														</td>
													))}
												</tr>
												{row.getIsExpanded() && (
													<tr className='shadow-inner'>
														<td
															colSpan={row.getVisibleCells().length}
															className='!bg-slate-100'>
															{renderSubComponent({ row })}
														</td>
													</tr>
												)}
											</Fragment>
										))}
									</Fragment>
								) : (
									<tr>
										<td colSpan={100} className='text-center'>
											No record found
										</td>
									</tr>
								)}
							</tbody>
						)}
					</Fragment>
				</table>
			</div>

			{pagination && meta && data?.length > 0 ? (
				<DataTablePagination table={table} meta={meta} />
			) : undefined}
		</React.Fragment>
	)
}

DataTable.propTypes = {
	columns: PropTypes.array,
	data: PropTypes.array,
	pagination: PropTypes.shape({ pageIndex: PropTypes.number, pageSize: PropTypes.number }),
	meta: PropTypes.shape({
		currentPage: PropTypes.number,
		limit: PropTypes.number,
		totalPages: PropTypes.number,
		totalRecords: PropTypes.number,
		hasNextPage: PropTypes.bool,
		hasPrevPage: PropTypes.bool,
		nextPage: PropTypes.number,
		prevPage: PropTypes.number,
	}),
	setPagination: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	variant: PropTypes.oneOf(['primary', 'secondary']),
}
DataTable.defaultProps = {
	columns: [],
	data: [],
	variant: 'primary',
}

export default DataTable
