import { createContext, useContext, useState } from 'react'

const TableFilterContext = createContext()

export const useTableFilters = () => useContext(TableFilterContext)

export const TableFilterProvider = ({ children }) => {
	const [filters, setFilters] = useState({})

	const onFilterChange = (values) => setFilters(values)

	return (
		<TableFilterContext.Provider
			value={{
				filters: filters,
				setFilters: onFilterChange,
			}}>
			{children}
		</TableFilterContext.Provider>
	)
}
