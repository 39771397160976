import React, { useEffect } from 'react'

import { useAppRouter } from 'hooks'
import Spinner from 'theme/ui/spinner'
import { getParseQueryString } from 'utilities/helpers'
import { useLazyGetCardViewQuery } from '../task-api'
import Filters from '../task-listing/filters'
import Board from './board'

const KanbanViewContainer = () => {
	const [fetchTasks, { data, isUninitialized, isLoading }] = useLazyGetCardViewQuery()
	const { location } = useAppRouter()

	const columns = data?.columns

	useEffect(() => {
		if (location?.search) {
			const params = getParseQueryString(location?.search)
			fetchTasks(params)
		} else {
			fetchTasks()
		}
	}, [location?.search])
	return (
		<React.Fragment>
			<Filters type='kanban' />
			{isUninitialized || isLoading ? <Spinner /> : <Board columns={columns} />}
		</React.Fragment>
	)
}

export default KanbanViewContainer
