import { Field, Form, FormikProvider, useFormik } from 'formik'
import { Button } from 'theme/ui/forms'
import DateRangePickerField from 'theme/ui/forms/date-range-picker-field'
import Spinner from 'theme/ui/spinner'
import { getPayload, validationSchema } from '.'
import ClientDropdown from './client-dropdown'
import { useLazyGetDashboardUrlQuery } from './metabase-portfolio-api'

const MetabasePortfolio = () => {
	const [getDashboardUrl, { data, isLoading }] = useLazyGetDashboardUrlQuery()
	const metaBaseUrl = data?.data?.url

	const formik = useFormik({
		initialValues: {
			client_id: null,
			date_range: [],
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			const payload = getPayload(values)
			const params = { client_id: payload?.client_id }
			if (payload?.start_date && payload?.end_date) {
				Object.assign(params, {
					start_date: payload?.start_date,
					end_date: payload?.end_date,
				})
			}
			getDashboardUrl(params)
		},
	})

	return (
		<div className='-mt-[5px]'>
			<div className='mt-[-10px] flex min-h-[60px] items-center justify-end bg-white px-2'>
				<FormikProvider value={formik}>
					<Form>
						<div className='flex flex-wrap items-start gap-1'>
							<div className='w-[310px]'>
								<ClientDropdown name='client_id' />
							</div>
							<div className='max-w-[310px]'>
								<Field name='date_range' component={DateRangePickerField} />
							</div>

							<Button variant='primary' type='submit' disabled={isLoading}>
								Submit
							</Button>
							<Button variant='white' type='button' onClick={formik.handleReset}>
								Clear
							</Button>
						</div>
					</Form>
				</FormikProvider>
			</div>

			{isLoading ? (
				<div>
					<Spinner />
				</div>
			) : metaBaseUrl ? (
				<iframe
					src={metaBaseUrl}
					title='portfolio'
					style={{
						width: '100%',
						height: 'calc(100vh - 140px)',
						border: 'none',
					}}
					allowFullScreen
				/>
			) : (
				<div style={{ textAlign: 'center', marginTop: '20px' }}>
					<p>No portfolio available.</p>
				</div>
			)}
		</div>
	)
}

export default MetabasePortfolio
