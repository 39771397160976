import { useEffect, useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { useAppRouter } from 'hooks'
import { useLazyGetDocumentTemplatesQuery } from 'modules/documents/document-api'
import { FileManagerLayout } from 'modules/file-manager'
import DocumentsTable from 'modules/file-manager/my-documents-listing/documents-table'
import Filters from 'modules/file-manager/my-documents-listing/filters'
import { TableProvider } from 'theme/ui/data-table'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'

const MyDocuments = () => {
	const [rowSelection, setRowSelection] = useState({})
	const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection])
	const { location, navigate } = useAppRouter()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const [fetchDocumentTemplates, { data, isFetching }] = useLazyGetDocumentTemplatesQuery()
	const isMobile = useMediaQuery({ query: '(max-width: 768px' })

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			navigate(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	useEffect(() => {
		if (location?.search) {
			fetchDocumentTemplates({
				params: getParseQueryString(location?.search),
			})
		}
	}, [location?.search])

	const $data = useMemo(
		() =>
			Array.isArray(data?.documents)
				? data?.documents.map((document) => ({
						id: document?.id,
						title: document?.title,
						status: document?.status,
						version: document?.version,
						document_type: document?.documentType,
						isStarred: document?.isStarred,
						type: 'Document',
						folders: Array.isArray(document?.folders) ? document?.folders : [],
						last_edit: document?.last_edit,
				  }))
				: [],
		[data?.documents],
	)

	return (
		<FileManagerLayout
			meta={{
				title: 'File Manager - All Documents',
			}}
			filterForm={
				<Filters
					documents={data?.documents}
					isFetching={isFetching}
					selectedRows={selectedRows}
					heading='All Documents'
					setPagination={setPagination}
					setRowSelection={setRowSelection}
				/>
			}>
			<TableProvider>
				<div className={`${isMobile ? 'min-h-[75vh]' : ''}`}>
					<DocumentsTable
						data={$data}
						pagination={pagination}
						meta={data?.meta}
						isLoading={isFetching}
						setPagination={setPagination}
						setRowSelection={setRowSelection}
						rowSelection={rowSelection}
					/>
				</div>
			</TableProvider>
		</FileManagerLayout>
	)
}

export default MyDocuments
