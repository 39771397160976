import { flatten } from 'lodash'

export const CONSTANTS = {
	SELECTED_NONE: 'none',
}

export const FIELD_TYPE = {
	TEXT: 'text',
	SELECT: 'select',
	CHECKBOX: 'checkbox',
	RADIO: 'radio',
}
export const getDocumentResponse = (document) => {
	return {
		...document,
		pdf_fields: extractPdfFields(document?.file_data),
	}
}

const getTextFields = (field, pageNumber) => {
	return {
		x: field.x,
		y: field.y,
		label: field?.id?.Id,
		isCheckbox: false,
		type: FIELD_TYPE.TEXT,
		pageNumber: pageNumber,
		options: [],
	}
}

const getSelectFields = (field, pageNumber) => {
	const labels = Array.isArray(field?.PL?.D) ? field?.PL?.D.map((l) => l) : []
	const values = Array.isArray(field?.PL?.V) ? field?.PL?.V.map((l) => l) : []

	const options = values.map((value, idx) => ({
		label: labels[idx],
		value: value,
		selected: false,
	}))

	return {
		x: field.x,
		y: field.y,
		label: field?.id?.Id,
		isCheckbox: false,
		type: FIELD_TYPE.SELECT,
		pageNumber: pageNumber,
		options: options,
	}
}

const getRadioField = (field, pageNumber) => {
	return {
		x: field?.boxes[0]?.x,
		y: field?.boxes[0]?.y,
		label: field?.id?.Id,
		isCheckbox: false,
		type: FIELD_TYPE.RADIO,
		pageNumber: pageNumber,
		options: Array.isArray(field.boxes)
			? field.boxes.map((f) => ({
					label: f?.id?.Id,
					value: f?.id?.Id,
					selected: false,
			  }))
			: [],
	}
}

const getCheckboxField = (field, pageNumber) => {
	const $field = field.boxes[0]
	return {
		x: $field?.x,
		y: $field?.y,
		label: $field?.id?.Id,
		isCheckbox: true,
		type: FIELD_TYPE.CHECKBOX,
		pageNumber: pageNumber,
		checked: $field?.checked,
		options: [{ label: $field?.id?.Id, value: true }],
	}
}

const getAlphaFields = (fields, pageNumber) => {
	if (Array.isArray(fields)) {
		return fields
			.filter((f) => f?.T?.Name !== 'link')
			.map((field) => {
				if (field?.PL) {
					return getSelectFields(field, pageNumber)
				} else {
					return getTextFields(field, pageNumber)
				}
			})
			.filter((f) => typeof f !== 'undefined')
	} else {
		return []
	}
}

const getBoxsetFields = (fields, pageNumber) => {
	if (Array.isArray(fields)) {
		return fields
			.map((field) => {
				if (field?.boxes.length > 1) {
					return getRadioField(field, pageNumber)
				} else {
					return getCheckboxField(field, pageNumber)
				}
			})
			.filter((f) => typeof f !== 'undefined')
	} else {
		return []
	}
}

export const extractPdfFields = (pdfData) => {
	const $pages = Array.isArray(pdfData?.Pages) ? pdfData?.Pages : []
	const pages = $pages.map((page, idx) => {
		const textFields = getAlphaFields(page.Fields, idx + 1)
		const boxsetFields = getBoxsetFields(page.Boxsets, idx + 1)
		return [...textFields, ...boxsetFields]
	})

	return flatten(pages)
}

export const getInitialPdfFieldsMapping = (documentMapping, pdf_fields) => {
	if (Array.isArray(documentMapping) && Array.isArray(pdf_fields)) {
		if (documentMapping.length > 0) {
			return documentMapping
				.filter((docMap) => docMap?.user?.value !== 'none')
				.map((docMap) => {
					const pdfField = pdf_fields.find((field) => field?.label == docMap?.pdf_field)

					return {
						field: docMap?.field || null,
						user: docMap?.user || null,
						pdf_field: pdfField?.label,
						is_document_bound: docMap?.is_document_bound ? true : false,
					}
				})
		} else {
			return pdf_fields.map((field, idx) => {
				let Obj = {}
				if (documentMapping[idx]) {
					Object.assign(Obj, {
						field: documentMapping[idx]?.field || null,
						user: documentMapping[idx]?.user || null,
						pdf_field: field?.label,
						is_document_bound: field?.is_document_bound ? true : false,
					})
				} else {
					Object.assign(Obj, {
						field: null,
						user: null,
						pdf_field: field?.label,
						is_document_bound: field?.is_document_bound ? true : false,
					})
				}
				return Obj
			})
		}
	} else {
		return []
	}
}

export const toFormBuilder = (fields, pdfFields) => {
	try {
		let fieldsArray = []
		let fieldsArraySize = fields.length

		for (let index = 0; index < fieldsArraySize; index++) {
			let item = fields[index]
			const field = pdfFields.find((pf) => pf.label === item?.pdf_field)
			if (item?.user?.value !== CONSTANTS.SELECTED_NONE) {
				fieldsArray.push({
					type: field?.type || 'text',
					required: false,
					label: item?.field?.label,
					className: 'form-control',
					name: item?.field?.value,
					user_type: item?.user?.value,
					access: false,
					subtype: 'text',
					values: Array.isArray(field?.options) ? field?.options : [],
				})
			}
		}
		return fieldsArray
	} catch (error) {
		return []
	}
}

export const disabledFieldActions = (actions) => {
	const fields = [
		'autocomplete',
		'button',
		'checkbox-group',
		'checkbox',
		'date',
		'file',
		'header',
		'hidden',
		'number',
		'paragraph',
		'radio-group',
		'select',
		'text',
		'textarea',
	]
	const disableFields = {}
	fields.forEach((field) => {
		Object.assign(disableFields, {
			[field]: actions,
		})
	})
	return disableFields
}

export const prepareFieldsForApi = (formData) => {
	const arrayValues = ['checkbox', 'radio-group', 'checkbox-group', 'select']
	return formData.map((field) => ({
		...field,
		type: field?.subtype || field?.type,
		required: field?.required || false,
		values: arrayValues.includes(field?.subtype || field?.type) ? field?.values : [],
		description: field?.description || '',
		placeholder: field?.placeholder || '',
	}))
}

export const getMappedFields = (document_body, formik) => {
	try {
		const formBuilderFields = prepareFieldsForApi(document_body)
		const fieldMapping = formik?.values?.document_mapping || []

		const preparedFields = []
		for (let i = 0; i < formBuilderFields.length; i++) {
			const formBuilderField = formBuilderFields[i]
			const pdfField = fieldMapping[i]

			formBuilderField['user_type'] = pdfField?.user?.value
			formBuilderField['pdf_field'] = pdfField?.pdf_field
			formBuilderField['is_document_bound'] = pdfField?.is_document_bound
			preparedFields.push(formBuilderField)
		}

		return preparedFields
	} catch (error) {
		return []
	}
}

const decodeUTF16 = (encodedString) => {
	if (!containsNonBMPCharacters(encodedString)) {
		return encodedString
	}
	// Remove the BOM (þÿ or \u00FE\u00FF)
	const bomRemoved = encodedString.replace(/^\u00FE\u00FF/, '')

	// Decode the UTF-16 string
	let decoded = ''
	for (let i = 0; i < bomRemoved.length; i += 2) {
		const charCode = bomRemoved.charCodeAt(i + 1)
		decoded += String.fromCharCode(charCode)
	}

	return decoded
}

const containsNonBMPCharacters = (str) => {
	for (let i = 0; i < str.length; i++) {
		const code = str.charCodeAt(i)
		// Check if the character is a high surrogate (D800–DBFF)
		if (code >= 0xd800 && code <= 0xdbff) {
			// Check if the next character is a low surrogate (DC00–DFFF)
			if (i + 1 < str.length) {
				const nextCode = str.charCodeAt(i + 1)
				if (nextCode >= 0xdc00 && nextCode <= 0xdfff) {
					return true // The string contains a non-BMP character
				}
			}
		}
	}
	return false // No non-BMP characters found
}
