import classNames from 'classnames'
import { Field } from 'formik'
import { createElement } from 'react'
import Select from 'react-select'

import { FIELD_TYPES, FORM_CONTROLS } from 'modules/form-designer/helpers'
import DropdownIndicator from 'theme/ui/forms/select-field/dropdown-indicator'

const FieldTypeField = ({ name }) => {
	const createOption = (value) => {
		const option = FORM_CONTROLS.find((control) => {
			if ([FIELD_TYPES.CHOICE, FIELD_TYPES.CHECKBOX].includes(value)) {
				return FIELD_TYPES.CHOICE === control?.type
			} else {
				return control?.type === value
			}
		})
		return {
			label: (
				<div className='flex items-center gap-1'>
					<span>
						{createElement(option?.icon, {
							className: '',
							size: 14,
							strokeWidth: '2px',
						})}
					</span>
					<span>{option?.title}</span>
				</div>
			),
			value: option?.type,
		}
	}

	const options = FORM_CONTROLS.map((option) => createOption(option?.type))

	return (
		<div className='min-w-[140px]'>
			<Field name={`${name}.type`}>
				{({ field, form }) => (
					<Select
						{...field}
						value={field?.value ? createOption(field?.value) : null}
						options={options}
						onChange={(option) => form.setFieldValue(field?.name, option?.value)}
						isSearchable={false}
						isClearable={false}
						isMulti={field?.multiple}
						placeholder={'Select user'}
						// classNamePrefix={isInvalid ? 'invalid-select-dropdown' : 'select-dropdown'}
						classNamePrefix={'select-dropdown'}
						className={classNames('select-dropdown-container')}
						styles={{
							menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
							menu: (provided) => ({ ...provided, zIndex: 9999 }),
						}}
						menuPortalTarget={document.body}
						menuPosition={'fixed'}
						components={{
							DropdownIndicator: DropdownIndicator,
						}}
					/>
				)}
			</Field>
		</div>
	)
}

export default FieldTypeField
