/* eslint-disable no-case-declarations */
import { Fragment } from 'react'
import { Field } from 'redux-form'

import classNames from 'classnames'
import { CheckBox } from 'components/Forms/CheckBoxComponent'
import { RadioComponent } from 'components/Forms/RadioComponent'
import { SelectField } from 'components/Forms/SelectComponent'
import { TextField } from 'components/Forms/TextFieldComponent'
import { DatePickerInput, RatingField } from 'components/Forms2'
import MultiCheckbox from 'components/Forms2/multi-checkbox'
import ToolTip from 'theme/ui/tool-tip'
import { WorkflowStatuses } from 'utilities/helpers'
import { required } from 'utils/formValidation'

const PdfFieldRender = ({ field, i, error, isReadOnly, currentStatus }) => {
	const $isReadOnly =
		currentStatus === WorkflowStatuses.REJECTED ? field?.status === 'approved' : isReadOnly

	return (
		<Fragment>
			<FieldType
				field={field}
				i={i}
				name={`fields[${i}].${field?.name}`}
				isReadOnly={$isReadOnly}
			/>
			{error && (
				<div className='invalid-feedback' style={{ display: 'block' }}>
					{error}
				</div>
			)}
		</Fragment>
	)
}

export default PdfFieldRender

const validate = {
	field: [required('field value', 'enter')],
}

export const FieldType = ({ field, i, name, isReadOnly, isWorkflowView = false }) => {
	const type = field?.type || 'text'
	const documentList = (field) => {
		if (!field || !field.documents || field.documents.length === 0) {
			return <p>No Documents</p>
		}

		return (
			<ul className='m-0 p-0'>
				{field.documents.map((doc, index) => (
					<li className='m-0 p-0' key={index}>
						{doc?.title}
					</li>
				))}
			</ul>
		)
	}

	switch (type) {
		case 'text':
		case 'email':
		case 'number':
		case 'color':
			return (
				<Field
					label={isWorkflowView ? null : field?.label}
					infoText={isWorkflowView ? null : field?.description}
					type={field?.sub_type ? field?.sub_type : type}
					name={name}
					component={TextField}
					placeholder={field?.placeholder}
					isRequired={field?.required}
					validate={field?.required ? validate.field : undefined}
					autoComplete='off'
					tooltipIcon={'uil-file-blank'}
					isTooltip={true}
					tooltipText={documentList(field)}
					disabled={isReadOnly}
					ignoreBottomMargin={true}
				/>
			)
		case 'rating':
			return (
				<Field
					label={isWorkflowView ? null : field?.label}
					infoText={isWorkflowView ? null : field?.description}
					type={'date'}
					name={name}
					component={RatingField}
					ratingCount={field?.rating?.value}
					placeholder={field?.placeholder}
					isRequired={field?.required}
					validate={field?.required ? validate.field : undefined}
					autoComplete='off'
					tooltipIcon={'uil-file-blank'}
					isTooltip={true}
					tooltipText={documentList(field)}
					disabled={isReadOnly}
					ignoreBottomMargin={true}
				/>
			)
		case 'date':
			return (
				<Field
					label={isWorkflowView ? null : field?.label}
					infoText={isWorkflowView ? null : field?.description}
					type={'date'}
					name={name}
					component={DatePickerInput}
					dateFormat={field?.date_format?.value}
					placeholder={field?.placeholder}
					isRequired={field?.required}
					validate={field?.required ? validate.field : undefined}
					autoComplete='off'
					tooltipIcon={'uil-file-blank'}
					isTooltip={true}
					tooltipText={documentList(field)}
					disabled={isReadOnly}
					ignoreBottomMargin={true}
				/>
			)

		case 'select':
			const options = Array.isArray(field?.values)
				? field.values.map((option) => ({ label: option?.label, value: option?.value }))
				: []

			return (
				<Field
					label={isWorkflowView ? null : field?.label}
					infoText={isWorkflowView ? null : field?.description}
					type='select'
					name={name}
					component={SelectField}
					options={options}
					isRequired={field?.required}
					isMulti={field?.multiple}
					validate={field?.required ? validate.field : undefined}
					autoComplete='off'
					isDisabled={isReadOnly}
					tooltipIcon={'uil-file-blank'}
					isTooltip={true}
					tooltipText={documentList(field)}
					ignoreBottomMargin={true}
					max_selected_option={field?.max_selected_option}
				/>
			)

		case 'radio':
			const $radios = Array.isArray(field?.values) ? field.values : []

			return (
				<div className='form-group'>
					{!isWorkflowView && field?.label ? (
						<label className='mb-[1px] flex items-baseline justify-between text-[14px] font-bold text-[#495057]'>
							<span>
								{field?.label}{' '}
								{field?.required ? (
									<span className='text-red-500'>*</span>
								) : undefined}
								{documentList(field) && (
									<>
										<ToolTip
											targetId={`radio-tooltip-1`}
											tooltipText={documentList(field)}
											placement='top'
										/>
										<i
											id={`radio-tooltip-1`}
											className={`uil-file-blank ms-2 cursor-pointer`}></i>
									</>
								)}
							</span>
						</label>
					) : undefined}

					{!isWorkflowView && field?.description ? (
						<span className='text-[13px] text-gray-400'>{field?.description}</span>
					) : undefined}

					{field?.multiple ? (
						<Field
							type='checkbox'
							options={$radios}
							multiple={field?.multiple}
							name={name}
							component={CheckBox}
							readOnly={isReadOnly}
							ignoreBottomMargin={true}
							isWorkflowView={isWorkflowView}
							max_selected_option={field?.max_selected_option}
						/>
					) : (
						<div
							className={classNames(
								'mt-2 flex gap-2',
								isWorkflowView ? 'flex-wrap' : 'flex-col',
							)}>
							{$radios.map((item, idx) => {
								return (
									<Field
										key={idx}
										label={item?.label}
										id={item?.value}
										value={item?.value}
										type='radio'
										name={name}
										component={RadioComponent}
										readOnly={isReadOnly}
										ignoreBottomMargin={true}
									/>
								)
							})}
						</div>
					)}
				</div>
			)

		case 'checkbox':
			const checkboxOptions = Array.isArray(field?.values) ? field.values : []

			return (
				<Fragment>
					{checkboxOptions.length > 1 ? (
						<div className='flex-col'>
							<label className='mb-2'>
								{field?.label}{' '}
								{documentList(field) && (
									<>
										<ToolTip
											targetId={`checkbox-tooltip-1`}
											tooltipText={documentList(field)}
											placement='top'
										/>
										<i
											id={`checkbox-tooltip-1`}
											className={`uil-file-blank ms-2 cursor-pointer`}></i>
									</>
								)}
							</label>

							{field?.description && (
								<i className='text-muted mb-2' style={{ fontSize: 12 }}>
									{field?.description}
								</i>
							)}
							<Field
								name={name}
								component={MultiCheckbox}
								value={[]}
								options={checkboxOptions}
								readOnly={isReadOnly}
							/>
						</div>
					) : (
						<div className='flex-col'>
							<Field
								label={field?.label}
								name={name}
								id={`fields[${i}].${field?.name}`}
								component={CheckBox}
								readOnly={isReadOnly}
							/>
						</div>
					)}
				</Fragment>
			)

		case 'radio-group':
			const radios = Array.isArray(field?.values) ? field.values : []

			return (
				<Fragment>
					<div className='flex-col'>
						{!isWorkflowView && (
							<label className='mb-2'>
								{field?.label}
								{documentList(field) && (
									<>
										<ToolTip
											targetId={`radio-group-tooltip-1`}
											tooltipText={documentList(field)}
											placement='top'
										/>
										<i
											id={`radio-group-tooltip-1`}
											className={`uil-file-blank ms-2 cursor-pointer`}></i>
									</>
								)}
							</label>
						)}
						{!isWorkflowView && field?.description && (
							<i className='text-muted mb-2' style={{ fontSize: 12 }}>
								{field?.description}
							</i>
						)}
					</div>
					<div className='flex flex-wrap gap-x-3'>
						{radios.map((item, idx) => {
							return (
								<Fragment key={idx}>
									<Field
										label={item?.label}
										id={item?.value}
										value={item?.value}
										type='radio'
										name={name}
										component={RadioComponent}
										readOnly={isReadOnly}
									/>
								</Fragment>
							)
						})}
					</div>
				</Fragment>
			)

		case 'checkbox-group':
			const checkboxes = Array.isArray(field?.values) ? field.values : []

			return (
				<Fragment>
					<div className='flex-col'>
						{!isWorkflowView && (
							<label className='mb-2'>
								{field?.label}
								{documentList(field) && (
									<>
										<ToolTip
											targetId={`checkbox-group-tooltip-1`}
											tooltipText={documentList(field)}
											placement='top'
										/>
										<i
											id={`checkbox-group-tooltip-1`}
											className={`uil-file-blank ms-2 cursor-pointer`}></i>
									</>
								)}
							</label>
						)}
						{!isWorkflowView && field?.description && (
							<Fragment>
								<i className='text-muted mb-2' style={{ fontSize: 12 }}>
									{field?.description}
								</i>
							</Fragment>
						)}
					</div>
					<Field
						type='checkbox'
						options={checkboxes}
						multiple={field?.multiple}
						name={name}
						component={CheckBox}
						readOnly={isReadOnly}
					/>
				</Fragment>
			)

		default:
			return (
				<Field
					label={field?.label}
					infoText={field?.description}
					type='text'
					name={name}
					component={TextField}
					placeholder={field?.placeholder}
					isRequired={field?.required}
					validate={field?.required ? validate.field : undefined}
					autoComplete='off'
					disabled={isReadOnly}
				/>
			)
	}
}
