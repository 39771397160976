import * as yup from 'yup'

import { createSelectOption } from 'utilities/helpers'
import { createAssigneeOption } from './assignee-dropdown'
import { createClientOption } from './client-dropdown'
import TaskForm from './task-form'
import { createWatcherOption } from './watcher-dropdown'
import { createWorkflowOption } from './workflow-dropdown'

export default TaskForm
export const formatDate = (fromDate) => {
	try {
		if (!fromDate) {
			return null
		}
		const originalDate = new Date(fromDate)

		const year = originalDate.getUTCFullYear()
		const month = String(originalDate.getUTCMonth() + 1).padStart(2, '0')
		const day = String(originalDate.getUTCDate()).padStart(2, '0')
		const hours = String(originalDate.getUTCHours()).padStart(2, '0')
		const minutes = String(originalDate.getUTCMinutes()).padStart(2, '0')
		const seconds = String(originalDate.getUTCSeconds()).padStart(2, '0')

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
	} catch (error) {
		return null
	}
}

export const formatDateForCalendar = (inputDateStr) => {
	try {
		// Convert input date string to a Date object
		var inputDate = new Date(inputDateStr)

		// Extract year, month, and day components
		var year = inputDate.getFullYear()
		var month = String(inputDate.getMonth() + 1).padStart(2, '0')
		var day = String(inputDate.getDate()).padStart(2, '0')

		// Format the date as "YYYY-MM-DD"
		return `${year}-${month}-${day}`
	} catch (error) {
		return null
	}
}

const dayMap = {
	MO: 'Mon',
	TU: 'Tue',
	WE: 'Wed',
	TH: 'Thu',
	FR: 'Fri',
	SA: 'Sat',
	SU: 'Sun',
}

const mapShortToFullDays = (shortDays) => shortDays.map((day) => dayMap[day] || day)

const frequencyMap = {
	DAILY: { label: 'Day', value: 'DAILY' },
	WEEKLY: { label: 'Week', value: 'WEEKLY' },
	MONTHLY: { label: 'Month', value: 'MONTHLY' },
	YEARLY: { label: 'Year', value: 'YEARLY' },
}

export const getInitialValues = (task) => {
	return {
		updateRecurringTasks: false,
		task_title: task?.task_title || '',
		progress_status: task?.progress_status
			? createSelectOption(task?.progress_status)
			: createSelectOption('To Do'),
		assignee_id: task?.assignee ? createAssigneeOption(task?.assignee) : null,
		watcher_ids: Array.isArray(task?.watchers)
			? task?.watchers.map((watcher) => createWatcherOption(watcher))
			: [],
		client_ids: task?.clients[0] ? createClientOption(task?.clients[0]) : [],
		tags: Array.isArray(task?.tags)
			? task?.tags.map((tag) => createSelectOption(tag?.tag))
			: [],
		workflow_id: task?.workflow ? createWorkflowOption(task?.workflow) : null,
		due_date: task?.due_date ? new Date(task?.due_date) : null,
		description: task?.description,
		attachment_ids: Array.isArray(task?.attachments)
			? task?.attachments.map((attachment) => ({
					file: attachment,
					id: attachment?.id,
			  }))
			: [],
		custom_fields: [],
		send_notification: task?.send_notification ? true : false,

		freq: ['DAILY', 'WEEKLY', 'MONTHLY', 'YEARLY']?.includes(
			task?.parent_task?.recurrence?.freq,
		)
			? { label: 'Custom', value: 'custom' }
			: { label: 'Does not repeat', value: 'NONE' },
		frequency_ids: task?.parent_task?.recurrence?.freq
			? frequencyMap[task?.parent_task?.recurrence?.freq]
			: frequencyMap.DAILY,
		repetition_ids:
			task?.parent_task?.recurrence?.end_type == 'BY_DATE'
				? { label: 'On a specific date', value: 'BY_DATE' }
				: { label: 'After X occurrences', value: 'BY_OCCURRENCES' },
		interval: task?.parent_task?.recurrence?.interval || 1,
		occurrence_count: task?.parent_task?.recurrence?.occurrence_count || null,
		by_day: task?.parent_task?.recurrence?.by_day
			? mapShortToFullDays(task?.parent_task?.recurrence?.by_day)
			: [],
		by_month_day: task?.parent_task?.recurrence?.by_month_day || null,
		end_date: task?.parent_task?.recurrence?.end_date
			? new Date(task?.parent_task?.recurrence?.end_date)
			: null,
	}
}

export const getPayload = (values) => {
	try {
		const recurrence = values?.freq?.value
		const frequency = values?.frequency_ids?.value?.toUpperCase()

		const isCustomRecurrence = recurrence === 'custom'
		const isNoneRecurrence = recurrence === 'NONE'
		const isWeekly = frequency === 'WEEKLY'
		const isMonthly = frequency === 'MONTHLY'

		const interval = isCustomRecurrence ? Number(values?.interval || 1) : 1
		const freq = isCustomRecurrence ? frequency || 'DAILY' : 'NONE'

		const by_day = isWeekly ? indexToDay(values.by_day) : undefined
		const by_month_day = isMonthly ? values?.by_month_day : undefined
		const end_type = values?.repetition_ids?.value?.toUpperCase() || 'BY_OCCURRENCES'
		const end_date = end_type === 'BY_DATE' ? formatDate(values?.end_date) : undefined
		const occurrence_count =
			end_type === 'BY_OCCURRENCES'
				? Number(values?.occurrence_count) || undefined
				: undefined
		const payload = {
			team_id: values?.team_id,
			task_title: values?.task_title,
			assignee_id: values?.assignee_id?.value,
			progress_status: values?.progress_status?.value,
			watcher_ids: values?.watcher_ids?.map((w) => w?.value) || [],
			client_ids: values?.client_ids?.value ? [values.client_ids.value] : [],
			tags: values?.tags?.filter(Boolean).map((tag) => tag?.value) || [],
			workflow_id: values?.workflow_id?.value,
			due_date: formatDate(values?.due_date),
			description: values?.description,
			custom_fields: [],
			attachment_ids: values?.attachment_ids?.map((a) => a?.id) || [],
			send_notification: values?.send_notification ?? true,
			updateRecurringTasks: values?.updateRecurringTasks || false,
		}
		if (isNoneRecurrence) {
			Object.assign(payload, {
				freq,
			})
		}
		if (isCustomRecurrence) {
			Object.assign(payload, {
				interval,
				freq,
				by_day,
				by_month_day,
				end_type,
				end_date,
				occurrence_count,
			})
		}

		return payload
	} catch (error) {
		return {
			assignee_id: null,
			watcher_ids: [],
			client_ids: [],
			tags: [],
			workflow_id: null,
			due_date: null,
			custom_fields: [],
			send_notification: true,
			updateRecurringTasks: false,
		}
	}
}
const indexToDay = (weekDay) => {
	return weekDay.map((day) => day.slice(0, -1).toUpperCase())
}

export const validationSchema = yup.object().shape({
	task_title: yup
		.string()
		.test('len', 'The title must not exceed 100 characters in length.', (val) => {
			return val.length <= 100
		})
		.required('Please enter title'),
	progress_status: yup.mixed().required('Please select progress status'),
	workflow_id: yup.mixed().required('Please select at least one workflow'),
})

export const copyToClipboard = (text) => {
	const textarea = document.createElement('textarea')

	textarea.value = text
	textarea.style.position = 'fixed'
	textarea.style.top = '0'
	textarea.style.left = '0'
	textarea.style.opacity = '0'

	document.body.appendChild(textarea)

	textarea.select()

	try {
		document.execCommand('copy')
	} catch (err) {
		//
	} finally {
		document.body.removeChild(textarea)
	}
}

export const createOption = (label, value) => ({ label, value })

export const recurringTaskOptions = [
	createOption('Does not repeat', 'NONE'),
	createOption('Custom', 'custom'),
]
export const frequencyOptions = [
	createOption('Day', 'DAILY'),
	createOption('Week', 'WEEKLY'),
	createOption('Month', 'MONTHLY'),
	createOption('Year', 'YEARLY'),
]
export const repetitionSFTPOptions = [
	createOption('After X occurrences', 'BY_OCCURRENCES'),
	createOption('On a specific date', 'BY_DATE'),
	createOption('Never', 'NEVER'),
]
export const repetitionOptions = [
	createOption('After X occurrences', 'BY_OCCURRENCES'),
	createOption('On a specific date', 'BY_DATE'),
]
