import { useGetSystemFieldsQuery } from 'modules/system-fields/system-fields-api'
import { useMemo } from 'react'

const useSystemFieldOptionsQuery = () => {
	const { isLoading, refetch, data } = useGetSystemFieldsQuery()

	const fields = useMemo(
		() =>
			Array.isArray(data?.data?.documentFields)
				? data?.data?.documentFields.map((field) => ({
						label: field?.field_name,
						value: field?.slug,
				  }))
				: [],
		[data?.data?.documentFields],
	)

	return {
		options: fields,
		isLoading,
		refetch,
	}
}

export default useSystemFieldOptionsQuery
