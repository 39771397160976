import { GripHorizontal } from 'lucide-react'
import { createElement, useState } from 'react'

import classNames from 'classnames'
import { Button } from 'theme/ui/forms'
import FieldRender from '../field-render'
import { FieldActions } from '../field-render/components'
import { FIELD_MODE, FORM_CONTROLS, OPTION_ABLE_FIELDS } from '../helpers'

const FormFieldRow = ({
	name,
	formik,
	arrayHelper,
	index,
	field,
	title,
	mode,
	totalFields,
	is_score_able,
	is_read_mode,
}) => {
	const [isHover, setIsHover] = useState(false)

	// function to remove the field.
	const onRemove = (index) => {
		arrayHelper.remove(index)
	}

	// function to clone the field.
	const onClone = (field) => {
		const $field = { ...field, name: `question_${totalFields + 1}` }
		arrayHelper.push($field)
	}

	// function to select rating field.
	const onRatingChange = (name, option) => {
		formik.setFieldValue(`${name}.rating`, option)
	}

	// function to select date format.
	const onDateChange = (name, option) => {
		formik.setFieldValue(`${name}.date_format`, option)
	}
	// function to set field type.
	const onSelectFieldType = (name, field) => {
		if (OPTION_ABLE_FIELDS.includes(field?.type)) {
			const options = { selected: false, label: 'Option 1', value: 'option-1' }
			formik.setFieldValue(`${name}.values`, [options])
		}
		formik.setFieldValue(`${name}.type`, field?.type)
	}

	const onChange = (name, value) => {
		formik.setFieldValue(`${name}.value`, value)
	}

	// function to add options to select, checkbox, and radio fields.
	const onAddOption = (name, field, isOther = false) => {
		const $options = Array.isArray(field?.values) ? [...field.values] : []
		if (isOther) {
			const otherIndex = $options.findIndex((option) => option.value === 'other')
			if (otherIndex === -1) {
				$options.push({ selected: false, label: 'Other', value: 'other' })
			}
		} else {
			const newOption = {
				selected: false,
				label: `Option ${$options.length + 1}`,
				value: `option-${$options.length + 1}`,
			}
			const otherIndex = $options.findIndex((option) => option.value === 'other')
			if (otherIndex !== -1) {
				$options.splice(otherIndex, 0, newOption)
			} else {
				$options.push(newOption)
			}
		}

		formik.setFieldValue(`${name}.values`, $options)
	}

	// function to remove options to select, checkbox, and radio fields.
	const onRemoveOption = (name, field, index) => {
		const $options = Array.isArray(field?.values) ? field?.values : []
		if (index >= 0 && index < $options.length) {
			$options.splice(index, 1)
			formik.setFieldValue(`${name}.values`, $options)
		} else {
			console.warn(`Invalid index ${index}. Unable to remove option.`)
		}
	}

	// function to re-arrange the options order
	const $options = Array.isArray(field?.values) ? field?.values : []
	const onDragEnd = (fromIndex, toIndex) => {
		const option = $options.splice(fromIndex, 1)[0]
		$options.splice(toIndex, 0, option)
		formik.setFieldValue(`${name}.values`, $options)
	}

	return (
		<div
			className={classNames(
				'field-item flex flex-col gap-2 pb-4',
				mode === FIELD_MODE.EDIT && isHover && totalFields > 1 ? 'pt-[1px]' : 'pt-4',
			)}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}>
			{mode === FIELD_MODE.EDIT ? (
				<>
					<div className='flex w-full items-center justify-center'>
						{isHover && totalFields > 1 ? (
							<Button
								type='button'
								size='sm'
								variant='ghost-white'
								block={true}
								className='drag-field-item text-center hover:!cursor-grab active:!cursor-grabbing'>
								<div className='flex justify-center'>
									<GripHorizontal size={14} />
								</div>
							</Button>
						) : (
							<span className='h-[1px]'></span>
						)}
					</div>
					<div className='flex items-center justify-between'>
						<h3 className='m-0 text-lg font-bold text-gray-700'>{title}</h3>
						<FieldActions
							name={name}
							field={field}
							index={index}
							onClone={onClone}
							onRemove={onRemove}
							is_score_able={is_score_able}
							is_read_mode={is_read_mode}
							totalFields={totalFields}
						/>
					</div>
				</>
			) : undefined}

			{field?.type ? (
				<FieldRender
					mode={mode}
					field={field}
					name={name}
					onChange={onChange}
					onAddOption={onAddOption}
					onRatingChange={onRatingChange}
					onRemoveOption={onRemoveOption}
					onDateChange={onDateChange}
					onDragEnd={onDragEnd}
					formik={formik}
					is_read_mode={is_read_mode}
					totalFields={totalFields}
				/>
			) : (
				<div className='flex flex-wrap justify-center gap-3'>
					{FORM_CONTROLS.map((field, i) => (
						<button
							type='button'
							onClick={() => onSelectFieldType(name, field)}
							key={i}
							className='flex h-[120px] w-[120px] cursor-pointer flex-col items-center justify-center gap-2 rounded-md !border hover:border-main hover:text-main active:bg-main/20'>
							<span>
								{createElement(field?.icon, {
									className: '',
									size: 34,
									strokeWidth: '1px',
								})}
							</span>
							<span className='text-base font-semibold'>{field?.title}</span>
						</button>
					))}
				</div>
			)}
		</div>
	)
}
export default FormFieldRow
