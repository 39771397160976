import { FieldArray } from 'formik'
import { useMemo, useState } from 'react'
import Select from 'react-select'

import { useDocmentViewer } from 'pages/Authentication/profile/components/workflow/document-viewer-provider'
import DropdownIndicator from 'theme/ui/forms/select-field/dropdown-indicator'
import { getCoordinates } from '../pdf-viewer'
import FormRow from './form-row'

const PdfFields = ({ formik, onAutoSave, document }) => {
	const documentInstance = useDocmentViewer()
	const [lastAnnotation, setLastAnnotation] = useState(null)
	const [annots, setAnnots] = useState([])
	// eslint-disable-next-line no-unused-vars
	const [page, setPage] = useState(1)
	const [selectAll, setSelectAll] = useState(false)
	const [globalUser, setGlobalUser] = useState(null)
	const [selectedMapping, setSelectedMapping] = useState([])
	const pdf_fields = useMemo(
		() => (Array.isArray(document?.pdf_fields) ? document?.pdf_fields : []),
		[document],
	)

	const formValues = useMemo(() => formik?.values, [formik?.values])
	const numberOfUsers = formValues?.min_clients?.value

	const userOptions = useMemo(() => {
		const users = [
			{ label: 'Advisor', value: 'advisor' },
			{ label: 'Client', value: 'client' },
		]
		if (numberOfUsers > 1) {
			users.push(
				...new Array(numberOfUsers - 1).fill(0).map((_, idx) => ({
					label: `Co Applicant ${idx + 1}`,
					value: `applicant_${idx + 1}`,
				})),
			)
		}
		return users
	}, [numberOfUsers])

	const handleSelectAll = (event) => {
		const isChecked = event.target.checked
		setSelectAll(isChecked)
		if (isChecked) {
			const mappingElements = formValues?.document_mapping?.map((elem, idx) => {
				return `document_mapping[${idx}].user`
			})
			if (mappingElements?.length > 0) {
				setGlobalUser(null)
				setSelectedMapping(mappingElements)
			}
		}
		if (!isChecked) {
			setSelectedMapping([])
			setGlobalUser(null)
		}
	}

	const handleMappingCheck = (e, id) => {
		const isChecked = e.target.checked

		setSelectedMapping((prevSelected) => {
			if (isChecked) {
				return [...prevSelected, id]
			} else {
				return prevSelected.filter((item) => item !== id)
			}
		})
	}

	const onHighlight = async (field) => {
		try {
			const {
				Annotations,
				annotationManager,
				Math: MathViewer,
				DocumentViewer,
			} = documentInstance.Core
			const coordinates = getCoordinates(field)

			const displayMode = new DocumentViewer()
			displayMode.setCurrentPage(Number(page), true)
			// displayMode.displayPageLocation(Number(page), coordinates.x - 40, coordinates.y - 20)

			if (annots.length > 0) {
				annots.forEach((annotId) => {
					const annot = annotationManager.getAnnotationById(annotId)
					if (annot) {
						annotationManager.deleteAnnotation(annot)
					}
				})
			}

			if (lastAnnotation) {
				await annotationManager.hideAnnotation(lastAnnotation)
			}
			const { Rect } = MathViewer
			annotationManager.deleteAnnotations(annotationManager.getAnnotationsList())
			const quads = Rect.createFromDimensions(
				coordinates.x,
				coordinates.y,
				coordinates.width,
				coordinates.height,
			).toQuad()
			const highlight = new Annotations.TextHighlightAnnotation()
			highlight.PageNumber = page
			highlight.FillColor = [255, 0, 0]
			highlight.StrokeColor = new Annotations.Color(255, 0, 0)
			highlight.Quads = [quads]
			const $annots = [...annots]
			$annots.push(highlight?.Id)
			setAnnots($annots)
			annotationManager.addAnnotation(highlight)
			annotationManager.drawAnnotations(highlight.PageNumber)
			setLastAnnotation(highlight)
		} catch (error) {
			//
		}
	}

	return (
		<div className='height_auto_fix h-[100vh] rounded'>
			<div className='slim-scroll !max-h-[92%] overflow-auto rounded-b bg-white !px-3 !py-3'>
				<div className='flex w-full items-center justify-between p-1'>
					<div className='flex items-center gap-1'>
						<input
							type='checkbox'
							checked={selectAll}
							className='form-check-input m-0 ms-1 p-0'
							onChange={handleSelectAll}
						/>
						<span className='whitespace-nowrap'>
							Select all for Bulk User Permissions
						</span>
					</div>
					<div className=''>
						<Select
							className='  !w-36 rounded '
							value={globalUser}
							options={userOptions}
							onChange={(selectedOption) => {
								setGlobalUser(selectedOption || null)
							}}
							isDisabled={selectedMapping?.length === 0}
							components={{
								DropdownIndicator: DropdownIndicator,
							}}
							placeholder={'Select type'}
						/>
					</div>
				</div>
				<FieldArray
					name='document_mapping'
					render={(arrayHelpers) => (
						<div className='flex flex-col divide-y'>
							{formValues?.document_mapping.map((field, idx) => {
								const pdf_field = pdf_fields.find(
									($field) => $field?.label === field?.pdf_field,
								)

								return (
									<FormRow
										key={idx}
										field={field}
										formik={formik}
										pdf_field={pdf_field}
										formValues={formValues}
										onAutoSave={onAutoSave}
										onHighlight={onHighlight}
										userOptions={userOptions}
										selectedMapping={selectedMapping}
										selectAll={selectAll}
										handleMappingCheck={handleMappingCheck}
										globalUser={globalUser}
										onRemove={() => arrayHelpers.remove(idx)}
										fieldName={`${arrayHelpers.name}[${idx}]`}
									/>
								)
							})}
						</div>
					)}
				/>
			</div>
		</div>
	)
}

export default PdfFields
