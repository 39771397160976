import { Field, FormikProvider, useFormik } from 'formik'
import { useUser } from 'hooks'
import { useMemo, useState } from 'react'
import { OffcanvasBody } from 'reactstrap'
import { Button, TextareaField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { getInitialValues, getPayload, validationSchema } from '.'
import { useCreateTaskMutation, useUpdateTaskMutation } from '../task-api'
import AssigneeDropdown from './assignee-dropdown'
import Attachments from './attachments'
import ClientDropdown from './client-dropdown'
import CopyLinkBtn from './copy-link-btn'
import CustomModal from './custom-modal'
import DatePicker from './date-picker/DatePicker'
import NotificationButton from './notification-button'
import RecurringTask from './recurring-tasks/RecurringTask'
import StatusDropdown from './status-dropdown'
import TagDropdown from './tag-dropdown'
import TaskTitleField from './task-title-field'
import WatcherDropdown from './watcher-dropdown'
import WorkflowDropdown from './workflow-dropdown'

const TaskForm = ({ onClose, task, isTask }) => {
	const { team } = useUser()
	const initialValues = getInitialValues(task)
	const [createTask] = useCreateTaskMutation()
	const [updateTask] = useUpdateTaskMutation()

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isUpdateAll, setIsUpdateAll] = useState(false)

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			try {
				setSubmitting(true)
				const isRecurrenceUpdated =
					values?.freq?.value !== initialValues?.freq?.value ||
					values?.interval !== initialValues?.interval ||
					values?.by_day?.length !== initialValues?.by_day?.length ||
					values?.by_month_day !== initialValues?.by_month_day ||
					values?.end_date !== initialValues?.end_date ||
					values?.occurrence_count !== initialValues?.occurrence_count

				if (!isRecurrenceUpdated && task) {
					setIsModalOpen(true)
					setSubmitting(false)
					return
				}

				await submitForm(values, isRecurrenceUpdated)
				setSubmitting(false)
				onClose()
				resetForm()
			} catch (error) {
				setSubmitting(false)
				notification('warn', error)
			}
		},
	})

	const submitForm = async (values, isRecurrenceUpdated) => {
		const payload = getPayload(
			{
				...values,
				team_id: team?.id,
				updateRecurringTasks: isRecurrenceUpdated || isUpdateAll,
			},
			initialValues,
		)

		let response = null
		if (task) {
			response = await updateTask({ payload, id: task?.id }).unwrap()
		} else {
			response = await createTask(payload).unwrap()
		}

		if (response?.status === 200) {
			notification('success', response?.message)
		}
	}

	const handleUpdateSingle = async () => {
		setIsModalOpen(false)
		await submitForm(formik.values, false)
		onClose()
	}

	const handleUpdateAll = async () => {
		setIsModalOpen(false)
		setIsUpdateAll(true)
		await submitForm(formik.values, true)
		onClose()
	}

	const formValues = useMemo(() => formik?.values, [formik?.values])

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<div className='offcanvas-header border-b !px-6 py-[12px]'>
					<div className='createform-alignment flex w-full justify-between'>
						<StatusDropdown formik={formik} />
						<div className='space-x-1'>
							<NotificationButton formik={formik} />
							{task ? <CopyLinkBtn link={window.location.href} /> : undefined}
							<button
								type='button'
								className='btn !bg-[#F9F9F9] !p-1.5'
								onClick={onClose}>
								<i className='fas fa-times px-2 text-[16px]' />
							</button>
						</div>
					</div>
				</div>
				<OffcanvasBody className='min-h-screen !px-6'>
					<div className='!mb-3 grid grid-cols-1 gap-3'>
						<TaskTitleField />
						<AssigneeDropdown />
						<ClientDropdown />
						{formValues?.client_ids?.id && (
							<WorkflowDropdown formValues={formValues} formik={formik} />
						)}
						<div className='grid grid-cols-2 gap-3'>
							<DatePicker />
							<TagDropdown formValues={formValues} />
						</div>
						<Field
							label='Description'
							name='description'
							type='textarea'
							component={TextareaField}
						/>
						<RecurringTask formik={formik} isTask={isTask} />
						<div className='!border-b border-gray-200 !py-2'>
							<span className='font-semibold text-gray-500'>More Fields</span>
						</div>
						<WatcherDropdown />
						<Attachments formik={formik} />
					</div>
					<div className='flex justify-end border-t py-3'>
						<Button type='submit' isLoading={formik.isSubmitting}>
							{task ? 'Update' : 'Create'}
						</Button>
					</div>
				</OffcanvasBody>
			</form>

			<CustomModal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onUpdateSingle={handleUpdateSingle}
				onUpdateAll={handleUpdateAll}
			/>
		</FormikProvider>
	)
}

export default TaskForm
