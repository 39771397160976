/* eslint-disable react-hooks/exhaustive-deps */
import {
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	useReactTable,
} from '@tanstack/react-table'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import classNames from 'classnames'
import Spinner from '../spinner'
import DataTablePagination from './data-table-pagination'

const ReactDataTable = ({
	columns,
	data,
	isLoading,
	meta,
	pagination,
	setPagination,
	renderSubComponent,
	getRowCanExpand,
	hideHeader = false,
	isChildren = false,
	isFixedSize = false,
}) => {
	const table = useReactTable({
		columns: columns || [],
		data: data || [],
		getCoreRowModel: getCoreRowModel(),
		getRowCanExpand: getRowCanExpand,
		getExpandedRowModel: getExpandedRowModel(),
		manualPagination: true,
		pageCount: meta?.totalPages ?? -1,
		state: { pagination },
		onPaginationChange: setPagination,
	})

	return (
		<React.Fragment>
			<div className='table-responsive overflow-x-auto'>
				<table
					className={classNames(
						'table-1 table-bordered table-hover m-0',
						isFixedSize ? 'table-fixed' : '',
					)}>
					<thead className={classNames(hideHeader ? 'hidden' : '')}>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<th
										key={header.id}
										className={classNames(
											'whitespace-nowrap border  text-left',
											hideHeader ? 'invisible m-0 !h-[1px] p-0' : 'px-3 py-2',
										)}>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext(),
											  )}
									</th>
								))}
							</tr>
						))}
					</thead>

					<tbody>
						<Fragment>
							{isLoading ? (
								<tr>
									<td
										colSpan={table.getAllLeafColumns().length}
										className='p-4 text-center'>
										<div>
											<Spinner label={'Loading...'} />
										</div>
									</td>
								</tr>
							) : (
								<Fragment>
									{table.getRowModel().rows.length > 0 ? (
										<Fragment>
											{table.getRowModel().rows.map((row) => (
												<Fragment key={row.id}>
													<tr>
														{row.getVisibleCells().map((cell) => (
															<td
																key={cell.id}
																className={classNames(
																	'border border-gray-300 px-3 py-2 align-middle',
																	isChildren
																		? row.getIsExpanded()
																			? 'bg-[#E7EDFF]'
																			: 'bg-slate-100'
																		: row.getIsExpanded()
																		? '!bg-[#E7EDFF]'
																		: '',
																)}>
																{flexRender(
																	cell.column.columnDef.cell,
																	cell.getContext(),
																)}
															</td>
														))}
													</tr>
													{row.getIsExpanded() && (
														<tr className='shadow-inner'>
															<td
																colSpan={
																	row.getVisibleCells().length
																}
																className='border-b !bg-gray-50 !p-0 shadow-inner'>
																{renderSubComponent({ row })}
															</td>
														</tr>
													)}
												</Fragment>
											))}
										</Fragment>
									) : (
										<tr>
											<td colSpan={100} className='text-center'>
												No record found
											</td>
										</tr>
									)}
								</Fragment>
							)}
						</Fragment>
					</tbody>
				</table>
			</div>

			{pagination && meta ? <DataTablePagination table={table} meta={meta} /> : undefined}
		</React.Fragment>
	)
}

ReactDataTable.propTypes = {
	columns: PropTypes.array,
	data: PropTypes.array,
	pagination: PropTypes.shape({ pageIndex: PropTypes.number, pageSize: PropTypes.number }),
	meta: PropTypes.shape({
		currentPage: PropTypes.number,
		limit: PropTypes.number,
		totalPages: PropTypes.number,
		totalRecords: PropTypes.number,
		hasNextPage: PropTypes.bool,
		hasPrevPage: PropTypes.bool,
		nextPage: PropTypes.number,
		prevPage: PropTypes.number,
	}),
	setPagination: PropTypes.func,
}
ReactDataTable.defaultProps = {
	columns: [],
	data: [],
}

export default ReactDataTable
