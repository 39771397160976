import { Field } from 'formik'
import { useEffect, useState } from 'react'

import { useUser } from 'hooks'
import { SystemFieldDropdown } from 'modules/shared'
import { useCreateSystemFieldMutation } from 'modules/system-fields/system-fields-api'
import { Button } from 'theme/ui/forms'
import UserDropdown from '../user-dropdown'
import DocumentBoundField from './document-bound-field'

const FormRow = ({
	field,
	formik,
	onRemove,
	pdf_field,
	fieldName,
	onAutoSave,
	formValues,
	onHighlight,
	userOptions,
	globalUser,
	handleMappingCheck,
	selectedMapping,
}) => {
	const [isDeleting, setIsDeleting] = useState(false)
	const { team } = useUser()
	const [createSystemField] = useCreateSystemFieldMutation()

	const onCreateOption = async (value) => {
		const team_ids = [team?.id]
		const payload = {
			documentFields: {
				field_name: value,
				is_system_field: team_ids.length > 0 ? false : true,
			},
		}
		await createSystemField(payload)
	}

	const onMoveToTrash = ($field) => {
		return new Promise((resolve) => {
			const delete_document_mapping = formValues?.delete_document_mapping
			formik.setFieldValue('delete_document_mapping', [...delete_document_mapping, $field])
			resolve({ message: 'field successfully moved to trash' })
		})
	}

	const onDelete = async ($field) => {
		try {
			setIsDeleting(true)
			onRemove()
			await onMoveToTrash($field)
			setIsDeleting(false)
		} catch (error) {
			setIsDeleting(false)
		}
	}
	useEffect(() => {
		if (globalUser && selectedMapping?.length > 0) {
			if (selectedMapping?.includes(`${fieldName}.user`)) {
				formik.setFieldValue(`${fieldName}.user`, globalUser)
			}
		}
	}, [globalUser, selectedMapping])

	return (
		<div className='rounded !px-2 !py-4 transition-all hover:bg-slate-50'>
			<div className='fields_system !mb-2 flex w-full items-start justify-between gap-2'>
				<div className='mt-4 pr-2 pt-3'>
					<input
						type='checkbox'
						className='form-check-input m-0 p-0'
						checked={selectedMapping?.includes(`${fieldName}.user`)}
						onChange={(e) => handleMappingCheck(e, `${fieldName}.user`)}
					/>
				</div>
				<SystemFieldDropdown
					label={field?.pdf_field}
					name={`${fieldName}.field`}
					onAutoSave={onAutoSave}
					onHighlight={() => onHighlight(pdf_field)}
					onCreateOption={onCreateOption}
					className={'!min-w-[55%]'}
				/>
				<Field
					type='hidden'
					name={`${fieldName}.pdf_field`}
					value={field?.pdf_field}
					component='input'
				/>
				<UserDropdown
					name={`${fieldName}.user`}
					options={userOptions}
					onAutoSave={onAutoSave}
				/>
				<div className='remove_topspacing pt-[33px]'>
					<Button
						type='button'
						size='xs'
						isLoading={isDeleting}
						hideLoadingText={true}
						variant='danger-outline'
						onClick={() => onDelete(field)}>
						<i className='uil-trash-alt' />
					</Button>
				</div>
			</div>
			<div className='ms-4 ps-2 '>
				<DocumentBoundField fieldName={`${fieldName}.is_document_bound`} />
			</div>
		</div>
	)
}

export default FormRow
