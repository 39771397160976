import { Field, FormikProvider } from 'formik'
import { DatePickerField, InputField, SelectField } from 'theme/ui/forms'
import {
	frequencyOptions,
	recurringTaskOptions,
	repetitionOptions,
	repetitionSFTPOptions,
} from '..'

const RecurringTask = ({ formik, isTask }) => {
	
	const handleDays = (isChecked, selectDay) => {
		const selectedDays = formik.values.by_day || []
		if (isChecked) {
			formik.setFieldValue('by_day', [...selectedDays, selectDay])
		} else {
			const filteredDays = selectedDays.filter((day) => day !== selectDay)
			formik.setFieldValue('by_day', filteredDays)
		}
	}

	return (
		<FormikProvider value={formik}>
			<div className='space-y-4 '>
				<Field
					label='Repeat the Task'
					name='freq'
					type='select'
					isMulti={false}
					placeholder='Select recurrence'
					options={recurringTaskOptions}
					component={SelectField}
					className='w-full'
				/>

				{formik?.values?.freq?.value === 'custom' && (
					<div className='mt-4 rounded-md border bg-gray-50 p-4'>
						{/* Repeat Every */}
						<div className='mt-3 grid grid-cols-2 gap-3'>
							<div className='flex flex-col'>
								<Field
									label='Repeat Every'
									type='number'
									min='1'
									name='interval'
									component={InputField}
									className='w-full rounded-md border p-2'
								/>
							</div>
							<Field
								name='frequency_ids'
								type='select'
								label='Recurrence'
								isMulti={false}
								placeholder='Select recurrence'
								options={frequencyOptions}
								component={SelectField}
								className='w-full'
							/>
						</div>

						{/* Weekly: Choose Days */}
						{formik?.values?.frequency_ids?.value === 'WEEKLY' && (
							<div className='mt-4'>
								<label className='text-sm font-medium text-gray-600'>
									Repeat On
								</label>
								<div className='mt-2 grid grid-cols-7 gap-2'>
									{['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(
										(day, index) => (
											<label key={index} className='flex items-center gap-1'>
												<input
													type='checkbox'
													className='mr-1'
													checked={formik.values.by_day.includes(day)}
													onChange={(e) =>
														handleDays(e.target.checked, day)
													}
												/>
												{day}
											</label>
										),
									)}
								</div>
							</div>
						)}

						{/* Monthly Recurrence */}
						{formik?.values?.frequency_ids?.value === 'MONTHLY' && (
							<div className='mt-4'>
								<label className='text-sm font-medium text-gray-600'>
									Repeat on
								</label>
								<div className='flex items-center gap-2'>
									<Field
										type='number'
										min='1'
										name='by_month_day'
										component={InputField}
										className='w-20 rounded-md border p-2'
									/>
									<span>of every month</span>
								</div>
							</div>
						)}

						{/* End Repeat Options */}
						<div className='mt-4'>
							<label className='text-sm font-medium text-gray-600'>Ends</label>
							<div className='mt-2 grid grid-cols-2 gap-3'>
								<Field
									name='repetition_ids'
									type='select'
									isMulti={false}
									placeholder='Recurrence'
									options={isTask ? repetitionOptions : repetitionSFTPOptions}
									component={SelectField}
									className='w-full'
								/>
								{formik?.values?.repetition_ids?.value === 'BY_OCCURRENCES' && (
									<Field
										type='number'
										min='1'
										placeholder='Number of occurrences'
										name='occurrence_count'
										component={InputField}
										className='w-full rounded-md border p-2'
									/>
								)}
								{formik?.values?.repetition_ids?.value === 'BY_DATE' && (
									<Field
										name='end_date'
										type='date'
										component={DatePickerField}
										minDate={new Date()}
										className='w-full'
									/>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		</FormikProvider>
	)
}

export default RecurringTask
