import { Fragment, useEffect, useState } from 'react'

import { useAppRouter } from 'hooks'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { useLazyGetTasksQuery } from '../task-api'
import Filters from './filters'
import TaskTable from './task-table'

const TaskListingContainer = () => {
	const { location, navigate } = useAppRouter()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const [columnVisibility, setColumnVisibility] = useState({
		'#': true,
		summary: true,
		workflow: true,
		progress_status: true,
		assignee: true,
		actions: true,
	})

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			navigate(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [fetchTasks, { data, isLoading }] = useLazyGetTasksQuery()
	useEffect(() => {
		if (location?.search) {
			const params = getParseQueryString(location?.search)
			fetchTasks(params)
		}
	}, [location?.search])

	return (
		<Fragment>
			<Filters
				setPagination={setPagination}
				type='list'
				columnVisibility={columnVisibility}
				setColumnVisibility={setColumnVisibility}
			/>
			<TaskTable
				data={data?.tasks || []}
				meta={data?.meta}
				isLoading={isLoading}
				pagination={pagination}
				setPagination={setPagination}
				columnVisibility={columnVisibility}
				setColumnVisibility={setColumnVisibility}
			/>
		</Fragment>
	)
}

export default TaskListingContainer
